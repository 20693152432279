import { BrowserProvider, JsonRpcSigner } from "ethers";
import { v4 } from "uuid";
import { saveAs } from 'file-saver';
import * as openpgp from "openpgp";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const connect = async (signer: JsonRpcSigner) => {
  const address = await signer.getAddress();

  const nonce = v4();
  const signature = await signer.signMessage(nonce);
  return { address, signature, nonce };
};

export const connectMetamask = async () => {
  if (!window.ethereum) {
    console.error("Please install MetaMask");
    return { error: "MetaMask not found" };
  }

  const provider = new BrowserProvider(window.ethereum);

  try {
    await provider.send("eth_requestAccounts", []);

    const signer = await provider.getSigner();
    const { address } = await connect(signer);

    return { address };
  } catch (error: any) {
    console.error("Error connecting to MetaMask:", error);
    return { error: error.message };
  }
};

export const signMessage = async (
  address: string,
  message: string
): Promise<string> => {
  if (!window.ethereum) {
    throw new Error("MetaMask not found");
  }

  const provider = new BrowserProvider(window.ethereum);
  const signer = await provider.getSigner(address);
  const signature = await signer.signMessage(message);
  return signature;
};

export const requestNetworkSwitch = async ({
  chainId,
  rpcUrl,
  chainName,
  explorerUrl,
  currency,
}: {
  chainId: string | number;
  rpcUrl: string;
  chainName: string;
  currency: string;
  explorerUrl?: string;
}) => {
  const hexChainId = "0x" + Number(chainId).toString(16);

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexChainId }],
    });
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: hexChainId,
              rpcUrls: [rpcUrl],
              chainName: chainName,
              nativeCurrency: {
                name: currency,
                symbol: currency,
                decimals: 18,
              },
              blockExplorerUrls: explorerUrl ? [explorerUrl] : [],
            },
          ],
        });
      } catch (error) {
        console.log("Error switching the network", error); // * TODO : Handle error
        return { error };
      }
    }
  }
};


export async function clientSideEncrypt(
  file: File,
  signature: string,
): Promise<File> {
  const fileBuffer = await file.arrayBuffer();
  const message = await openpgp.createMessage({
    binary: new Uint8Array(fileBuffer),
  });

  const encrypted = await openpgp.encrypt({
    message,
    passwords: [signature],
    format: "binary",
  });

  // Convert WebStream<Uint8Array> to BlobPart
  const response = new Response(encrypted as ReadableStream<Uint8Array>);
  const arrayBuffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  const encryptedBlob = new Blob([uint8Array], {
    type: "application/octet-stream",
  });
  const encryptedFile = new File(
    [encryptedBlob],
    "",
    {
      type: "application/octet-stream",
    },
  );
  return encryptedFile;
}

export async function clientSideDecrypt(encryptedFile: File, signature: string): Promise<ArrayBuffer> {
  const encryptedData = await encryptedFile.arrayBuffer();
  const message = await openpgp.readMessage({
    binaryMessage: new Uint8Array(encryptedData),
  });

  const decrypted = await openpgp.decrypt({
    message,
    passwords: [signature],
    format: "binary",
  });

  // Convert the decrypted data to an ArrayBuffer
  const response = new Response(decrypted.data as ReadableStream<Uint8Array>);
  const arrayBuffer = await response.arrayBuffer();

  return arrayBuffer;
}

export const test = async (jsonData: any, signature: string) => {
  const encryptedFile = await clientSideEncrypt(new File([JSON.stringify(jsonData)], "account.json"), signature);
  const encryptedFileblob = new Blob([encryptedFile], { type: 'application/octet-stream' });
  saveAs(encryptedFileblob, 'encrypted.json');

  const encryptedFileBlob = new Blob(['./encrypted.json'], { type: 'application/octet-stream' });
  const file = new File([encryptedFileblob], '', { type: 'application/octet-stream'}); // Create a File object

  const decryptedFile = await clientSideDecrypt(file, signature);
  const blob = new Blob([decryptedFile], { type: 'application/octet-stream' });
  saveAs(blob, 'decrypted.json');
  return
}


// Helper function to convert hex string to Uint8Array
export const hexStringToUint8Array = (hex: string): number[] => {
  const result: number[] = [];
  for (let i = 0; i < hex.length; i += 2) {
    result.push(parseInt(hex.substr(i, 2), 16));
  }
  return result;
};