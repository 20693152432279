import React from 'react';
import { useEffect } from "react";
import Footer from "../components/Footer";
import ScrollTopButton from "../components/common/ScrollTopButton";
import StakingSection from "../components/StakingSection";

const WHY_STAKE_TITLE = "Why Stake?"
const WHY_STAKE_DESCRIPTION = `
Staking plays a vital role in supporting the VanaTensor ecosystem
and the native Vana token. The VANA model empowers users to
promote and support the AI models they believe in, ensuring value
flows directly to that model. By staking, participants help
maintain the ecosystem’s balance and sustainability, aligning
incentives across the community.
`

const WHY_STAKE_TITLE_2 = "Why Stake with Us?"
const WHY_STAKE_DESCRIPTION_2 = `
Staking with us means directly supporting the Vana ecosystem as a
whole and our innovative VanaTensor DLP. Your stake drives the
creation of high-quality datasets, enhancing AI models while
ensuring value flows back to contributors and yourself. By
staking, you earn rewards tied to cutting-edge AI advancements
and help power a decentralized network dedicated to redefining
data ownership and AI development.
`

const HOW_TO_STAKE_TITLE = "How to Stake with Us?"
const HOW_TO_STAKE_DESCRIPTION = <React.Fragment>
  1. Go to <a href="http://datahub.vana.com" className="text-blue-500 underline">http://datahub.vana.com</a>. <br />
  2. Select  VanaTensor.
  <br />
  <br />
  <strong>Stake</strong>
  <br />
  1. Next to VanaTensor, click the blue “Stake” button.<br />
  2. Enter the amount you wish to stake and confirm your
  transaction.
  <br />
  <br />
  <strong>Manage Your Stakes</strong>
  <br />
  1. Use the black “Manage Stake” button to monitor your stakes.
</React.Fragment>

const Staking: React.FC = () => {
  useEffect(() => {
    console.log("Staking component mounted");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="relative pt-[150px]">
      <ScrollTopButton />
      <div className="md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative z-[5]">

        <StakingSection title={WHY_STAKE_TITLE} description={WHY_STAKE_DESCRIPTION} />
        <StakingSection title={WHY_STAKE_TITLE_2} description={WHY_STAKE_DESCRIPTION_2} />

        {/* Section: How to Stake */}
        <StakingSection title={HOW_TO_STAKE_TITLE} description={HOW_TO_STAKE_DESCRIPTION} />

        <Footer />
      </div>
    </div>
  );
};

export default Staking;
