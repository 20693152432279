const networks = {
  moksha: {
    chainId: "14800",
    rpcUrl: "https://rpc.moksha.vana.org",
    chainName: "Vana Moksha Testnet",
    explorerUrl: "https://moksha.vanascan.io",
    currency: "VANA",
    smartContracts: {
      dlp: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DLP || "0x40BEb91476968E17e41B553C679F81AB741Ce85f",
      dataRegistry: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DATA_REGISTRY || "0x8C8788f98385F6ba1adD4234e551ABba0f82Cb7C",
      teePool: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_TEE_POOL || "0xE8EC6BD73b23Ad40E6B9a6f4bD343FAc411bD99A",
    }
  },
  satori: {
    chainId: "14801",
    rpcUrl: "https://rpc.satori.vana.org",
    chainName: "Vana Satori Testnet",
    explorerUrl: "https://satori.vanascan.io",
    currency: "VANA",
    smartContracts: {
      dlp: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DLP || "0x40BEb91476968E17e41B553C679F81AB741Ce85f",
      dataRegistry: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DATA_REGISTRY || "0x8C8788f98385F6ba1adD4234e551ABba0f82Cb7C",
      teePool: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_TEE_POOL || "0x3c92fD91639b41f13338CE62f19131e7d19eaa0D",
    }
  },
  mainnet: {
    chainId: "1480",
    rpcUrl: "https://rpc.islander.vana.org",
    chainName: "Islander",
    explorerUrl: "https://islander.vanascan.io",
    currency: "VANA",
    smartContracts: {
      dlp: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DLP_MAINNET || "0xeaddb55edcbf4c08814ebcf661bb5980beb43826",
      dataRegistry: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DATA_REGISTRY_MAINNET || "0x8C8788f98385F6ba1adD4234e551ABba0f82Cb7C",
      teePool: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_TEE_POOL_MAINNET || "0x3c92fD91639b41f13338CE62f19131e7d19eaa0D",
    }
  },
}

const network = (process.env.REACT_APP_NETWORK || "mainnet") as keyof typeof networks;

if (!Object.keys(networks).includes(network)) {
  throw new Error(`Invalid network type: ${network}`);
}

let networkConfig = networks[network];
if (!networkConfig) {
  networkConfig = {} as any;
  networks[network] = networkConfig;
}

const config = {
  network,
  networkConfig
};

export { config, networks };
