import { useEffect } from "react";
import Footer from "../components/Footer";
import ScrollTopButton from "../components/common/ScrollTopButton";

const About: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const sampleDataStructure = {
    "prompt": "How can advancements in the philosophy of mind contribute to more sustainable practices in environmental conservation and protection?\n\nanswer in 2 sentences",
    "uniqueID": "00001f57fe1f06da35f3ed5b03375d3b5496debe44b5ae43e75bbbe72f2045a3",
    "responses": [
      {
        "model": "Llama3_70b",
        "response": "Advancements in the philosophy of mind can help us better understand the interconnectedness between humans and the environment, leading to a greater appreciation for the natural world and a more sustainable approach to conservation. By recognizing the intrinsic value of nature and the importance of preserving biodiversity, we can develop more ethical and effective strategies for protecting the environment."
      },
      {
        "model": "Gemma7b",
        "response": "Advancements in the philosophy of mind can help us better understand our relationship with the environment and the impact of our actions on the natural world. By promoting a deeper sense of interconnectedness and empathy towards all living beings, we may be more inclined to adopt sustainable practices and prioritize the protection of our environment."
      }
    ],
    "human_machine": 0,
    "number_of_times": 10
  };

  return (
    <div className='relative pt-[150px]'>
      <ScrollTopButton />
      <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative z-[5]'>
      <div className="pb-10">
          <p className='text-[40px] md:text-[60px] leading-[60px] font-semibold text-center text-white'>About</p>
        </div>
        <div className='flex flex-col gap-[30px] rounded-[30px] bg-white p-4 md:p-[60px] max-w-[960px] mx-auto z-[3] relative'>
          <div>
            <div className='w-full flex flex-col gap-[20px]'>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
                We are pioneers in decentralized AI, redefining how data and intelligence are created, owned, and monetized. At the core of our mission is the VanaTensor DLP, a revolutionary platform that combines human feedback with synthetic data to build high-quality datasets for training advanced AI models. Powered by the VANA network, we prioritize transparency, collaboration, and value distribution, ensuring that contributors, not centralized platforms, reap the rewards of their efforts.
              </p>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
                By leveraging cutting-edge blockchain technology and innovative training mechanisms, we empower the VANA community to participate in a decentralized ecosystem where data becomes a liquid asset and AI evolves through collective intelligence. Our vision is to create a future where AI serves humanity equitably, fostering innovation and opportunity for all.
              </p>
              
              <div className="mt-8">
                <h2 className='text-[24px] font-semibold mb-4'>Data Structure</h2>
                <p className='font-normal text-[20px] leading-[24px] text-[#000000B2] mb-4'>
                  Our platform utilizes a sophisticated data structure for managing data. Below is an example of how data is organized in our system:
                </p>
                
                <div className="bg-gray-100 p-6 rounded-lg">
                  <pre className="overflow-x-auto">
                    <code className="text-[14px] text-gray-800">
                      {JSON.stringify(sampleDataStructure, null, 2)}
                    </code>
                  </pre>
                </div>
                
                <div className="mt-6">
                  <h3 className='text-[20px] font-semibold mb-3'>Key Components:</h3>
                  <ul className="list-disc pl-6 text-[#000000B2]">
                    <li className="mb-2">Prompt - The original question or task presented to the models</li>
                    <li className="mb-2">UniqueID - A unique identifier for each prompt-response set</li>
                    <li className="mb-2">Responses - Multiple model outputs from different AI models (e.g., Llama3_70b, Gemma7b)</li>
                    <li className="mb-2">Frequency Counter - Number of times this prompt-response pair has been processed</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  )
}

export default About;
