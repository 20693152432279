interface StakingSectionProps {
  title: string;
  description: string | React.ReactNode;
}

const StakingSection: React.FC<StakingSectionProps> = ({ title, description }) => {
  return (
    <section className="pb-10">
      <div className="pb-10">
        <p className='text-[40px] md:text-[60px] leading-[60px] font-semibold text-center text-white'>{title}</p>
      </div>
      <div className="flex flex-col gap-[30px] rounded-[30px] bg-white p-4 md:p-[60px] max-w-[960px] mx-auto z-[3] relative">
        <p className="font-normal text-[20px] leading-[24px] text-[#000000B2]">
          {description}
        </p>
      </div>
    </section>
  );
}

export default StakingSection;