import React from 'react';

interface PromptLabelProps {
  handleClickOption: (method: string) => void;
  isDataSetSizeLimit: boolean;
}

const OptionButton: React.FC<{ label: string; emoji: string; onClick: () => void, isDataSetSizeLimit: boolean, }> = ({ label, emoji, onClick, isDataSetSizeLimit }) => (
  <div className="relative group w-full">
    <button
      onClick={onClick}
      className={`${isDataSetSizeLimit ? 'bg-slate-500' : 'bg-[#97FFFE]'} w-full hover-effect-button flex flex-1 justify-center items-center rounded-[100px] text-[16px] leading-[20px] font-medium py-[16px] gap-[4px]`}
      disabled={isDataSetSizeLimit}
    >
      <p>{emoji}</p>
      <p>{label}</p>
    </button>
    {/* Tooltip */}
    {isDataSetSizeLimit && (
      <div className="absolute bottom-[110%] left-1/2 transform -translate-x-1/2 w-max bg-black text-white text-sm rounded-md py-2 px-4 opacity-0 group-hover:opacity-100 transition-opacity">
        Please Submit Data to Continue
      </div>
    )}
  </div>
);

const PromptLabel: React.FC<PromptLabelProps> = ({ handleClickOption, isDataSetSizeLimit }) => {
  return (
    <div className='flex md:flex-row flex-col gap-[12px] w-full max-w-[800px] md:mt-[24px] mt-[20px]'>
      <OptionButton label="A is better" emoji="👈" isDataSetSizeLimit={isDataSetSizeLimit} onClick={() => handleClickOption("A")} />
      <OptionButton label="B is better" emoji="👉" isDataSetSizeLimit={isDataSetSizeLimit} onClick={() => handleClickOption("B")} />
      <OptionButton label="Tie" emoji="🤝" isDataSetSizeLimit={isDataSetSizeLimit} onClick={() => handleClickOption("Both")} />
      <OptionButton label="Both are bad" emoji="👎" isDataSetSizeLimit={isDataSetSizeLimit} onClick={() => handleClickOption("None")} />
    </div>
  );
}

export default PromptLabel;
